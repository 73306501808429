import React from "react";
import "../styles/loading.css";
import runner from "../assets/image/Runner.png";
import runner2 from "../assets/image/Runner2.png";

const LoadingScreen = () => {
  return (
    <div className="loading-container container-fluid">
      <img className="loading-image image1" src={runner} alt="Loading 1" />
      <img className="loading-image image2" src={runner2} alt="Loading 2" />
    </div>
  );
};

export default LoadingScreen;
