import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SponsorCarousel from "../components/SponsorCarousel";
import EventHeader from "../components/EventHeader";
import EventDetails from "../components/EventDetails";
import HomeButton from "../components/Button";

import {
  Items,
  eventProps,
  postProps,
  UserInfo,
  SelectedItem,
} from "../components/types";

import { generateSectionItems, generateMVPItem } from "../features/generator";

import runner from "../assets/image/Runner.png";

import { User } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

interface ResultPageProps {
  event: eventProps;
  post: postProps;
  selectedItems: Record<number, SelectedItem>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResultPage: React.FC<ResultPageProps> = ({
  event: defaultEvent,
  post: defaultPost,
  selectedItems: externalSelectedItems,
}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  const location = useLocation();
  const event = location.state?.event || defaultEvent;
  const post = location.state?.post || defaultPost;

  if (post.State === "unknown") {
    navigate("/");
  }

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  let items: Items[] = [];
  if (post.NumOfRunner && post.Type === "sectionForcast") {
    items = generateSectionItems(post.NumOfRunner);
  } else if (post.Type === "MVP") {
    items = [generateMVPItem()];
  }

  const fetchUserInfo = async (uid: string) => {
    const userRef = doc(db, "runners", uid);
    const userSnap = await getDoc(userRef);
    return userSnap.data();
  };

  const [internalSelectedItems, setInternalSelectedItems] = useState<
    Record<number, SelectedItem>
  >(externalSelectedItems || {});

  const [resultItems, setResultItems] = useState<
    { id: number; name: string }[]
  >([]);

  useEffect(() => {
    const getResultsData = async () => {
      const currentYear = new Date().getFullYear().toString();
      const newResultItems: { id: number; name: string }[] = [];

      try {
        if (post.Type === "MVP") {
          // MVPのデータを取得
          const mvpDocRef = doc(
            db,
            "events",
            currentYear + event.Title,
            "results",
            post.Type
          );
          const mvpDocSnap = await getDoc(mvpDocRef);

          if (mvpDocSnap.exists() && mvpDocSnap.data()) {
            const mvpdata = mvpDocSnap.data();
            newResultItems.push({ id: 1, name: mvpdata[1] });
          }
        } else if (post.Type === "sectionForcast") {
          // Sectionのデータを取得
          const sectionDocRef = doc(
            db,
            "events",
            currentYear + event.Title,
            "results",
            post.Type
          );
          const sectionDocSnap = await getDoc(sectionDocRef);

          if (sectionDocSnap.exists()) {
            const sectionData = sectionDocSnap.data();

            for (let i = 1; i <= post.NumOfRunner; i++) {
              const fieldName = i.toString();
              if (sectionData && sectionData[fieldName]) {
                newResultItems.push({ id: i, name: sectionData[fieldName] });
              }
            }
          }
        }
      } catch (error) {
        console.error("Error getting data");
      }

      setResultItems(newResultItems);
    };

    if (post.State === "result") {
      getResultsData();
    }
  }, [post, event]);

  let [itemswithinfo, setItemsWithInfo] = useState<UserInfo[]>([]);

  useEffect(() => {
    const transformItems = async () => {
      const promises = resultItems.map(async (item) => {
        const userInfo = await fetchUserInfo(item.name);
        return {
          id: item.id,
          name: userInfo?.name,
          grade: userInfo?.grade,
          affiliation: userInfo?.affiliation,
          likeaboutmyhometown: userInfo?.likeaboutmyhometown,
          best5000m: userInfo?.best5000m,
          best10000m: userInfo?.best10000m,
          enthusiasmfortheIzumo: userInfo?.enthusiasmfortheIzumo,
          imgUrl: userInfo?.imgUrl,
          thumbnail: userInfo?.thumbnail,
        };
      });

      const resolvedItems = await Promise.all(promises);
      setItemsWithInfo(resolvedItems);
    };

    transformItems();
  }, [resultItems]);

  let [selectedItems, setSelectedItems] = useState<UserInfo[]>([]);

  useEffect(() => {
    const fetchSelectedItems = async () => {
      // selectedItems が空の場合だけ Firestore からデータを取得
      if (user && Object.keys(internalSelectedItems).length === 0) {
        try {
          const userDoc = doc(
            db,
            "users",
            user.uid,
            "events",
            event.Title,
            "types",
            post.Type
          );
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data && data.runnerlist) {
              // ここで selectedItems を Firebase から取得したデータで補完します
              setInternalSelectedItems(data.runnerlist);
            }
          }
        } catch (error) {
          console.error("エラーが発生しました: ", error);
        }
      }
    };

    fetchSelectedItems();
  }, [user, event, post, internalSelectedItems]);

  useEffect(() => {
    const transformItems = async () => {
      // selectedItems の値を配列に変換
      const itemsArray = Object.values(internalSelectedItems);

      const promises = itemsArray.map(async (item) => {
        const userInfo = await fetchUserInfo(item.uid);
        return {
          id: item.id,
          name: userInfo?.name,
          grade: userInfo?.grade,
          affiliation: userInfo?.affiliation,
          likeaboutmyhometown: userInfo?.likeaboutmyhometown,
          best5000m: userInfo?.best5000m,
          best10000m: userInfo?.best10000m,
          enthusiasmfortheIzumo: userInfo?.enthusiasmfortheIzumo,
          imgUrl: userInfo?.imgUrl,
          thumbnail: userInfo?.thumbnail,
        };
      });

      const resolvedItems = await Promise.all(promises);

      setSelectedItems(resolvedItems);
    };

    transformItems();
  }, [internalSelectedItems]);

  const calculateCorrectAnswers = (
    answers: UserInfo[],
    userSelections: UserInfo[]
  ): number => {
    let correctCount = 0;
    answers.forEach((answer, index) => {
      if (userSelections[index] && answer.name === userSelections[index].name) {
        correctCount++;
      }
    });
    return correctCount;
  };

  const [correctCount, setCorrectCount] = useState<number>(0);

  useEffect(() => {
    setCorrectCount(calculateCorrectAnswers(itemswithinfo, selectedItems));
  }, [itemswithinfo, selectedItems]);

  return (
    <div>
      <SponsorCarousel />
      <EventHeader event={event} />
      <div className="container d-flex">
        <div className="postBanner row col-9">
          <EventDetails event={event} post={post} />
        </div>
      </div>
      <h3 className="px-3 mt-5">結果</h3>

      <div className="container text-center">
        <div className="list-group text-bold border-0">
          <div className="list-group-item list-group-item-action3 my-4">
            {itemswithinfo.map((item) => (
              <div
                className={`row section-selector link-underline link-underline-opacity-0 my-4 ${
                  item.name ? "bg-primary" : "bg-non-available"
                }`}
                style={{
                  backgroundColor: item.name ? "#cf0100" : "transparent",
                }}
              >
                <h3 className="col-3">
                  {post.Type === "MVP" ? "MVP" : `${item.id} 区`}
                </h3>

                <div className="col-1 bg-white dia-line"></div>

                <div className="col-8 m-0 d-flex align-items-center">
                  <div className="col-5 px-4">
                    <img
                      src={
                        (item.thumbnail &&
                          `https://drive.google.com/uc?id=${item.thumbnail}&.JPG`) ||
                        `${runner}`
                      }
                      className="img-fluid runner-icon"
                      alt="..."
                    />
                  </div>
                  <div className="col-7 p-0">
                    <h3 className="mb-0 text-left">
                      {(item.name && `${item.name}`) || "（ 未発表 ）"}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <h3 className="px-3">あなたの投票</h3>
      {post.Type === "sectionForcast" && (
        <div className="px-3 correct-banner">
          <h2 className="primary text-center fw-bold m-4 p-2 border border-primary p-3">
            {correctCount === post.NumOfRunner
              ? "全区間正解！おめでとう！"
              : correctCount === post.NumOfRunner - 1
              ? `おしい！${correctCount}問 正解！`
              : `正解数: ${correctCount}`}
          </h2>
        </div>
      )}
      <div className="container text-center">
        <div className="list-group text-bold">
          <div className="list-group-item list-group-item-action3 my-4">
            {items.map((item) => (
              <div
                key={item.id - 1}
                className={`row section-selector link-underline link-underline-opacity-0 my-4 ${
                  selectedItems[item.id - 1] ? "bg-primary" : "bg-non-available"
                }`}
                style={{
                  backgroundColor: selectedItems[item.id - 1]
                    ? "#cf0100"
                    : "transparent",
                }}
              >
                <h3 className="col-3">{item.name}</h3>

                <div className="col-1 bg-white dia-line"></div>

                <div className="col-8 m-0 d-flex align-items-center">
                  <div className="col-5 px-4">
                    <img
                      src={
                        selectedItems[item.id - 1] &&
                        selectedItems[item.id - 1].thumbnail
                          ? `https://drive.google.com/uc?id=${
                              selectedItems[item.id - 1].thumbnail
                            }&.JPG`
                          : `${runner}`
                      }
                      className="img-fluid runner-icon"
                      alt="..."
                    />
                  </div>
                  <div className="col-7 p-0">
                    <h3 className="mb-0 text-left">
                      {(selectedItems[item.id - 1] &&
                        `${selectedItems[item.id - 1].name}`) ||
                        "（ 投票なし ）"}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <HomeButton />
    </div>
  );
};

export default ResultPage;
