import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import EventHeader from "../components/EventHeader";
import EventDetails from "../components/EventDetails";
import HomeButton from "../components/Button";

import {
  eventProps,
  postProps,
  UserInfo,
  Items,
  SelectedItem,
} from "../components/types";

import { generateSectionItems, generateMVPItem } from "../features/generator";
import {
  postTypeformatter,
  eventTitleformatter,
} from "../components/stringFormatter";

import runner from "../assets/image/Runner.png";

import { User } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

interface PostPageProps {
  event: eventProps;
  post: postProps;
  selectedItems: Record<number, SelectedItem>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const PostPage: React.FC<PostPageProps> = ({
  event: defaultEvent,
  post: defaultPost,
  selectedItems: externalSelectedItems,
  setLoading,
}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  const location = useLocation();
  const event = location.state?.event || defaultEvent;
  const post = location.state?.post || defaultPost;

  if (post.State === 'unknown') {
    navigate("/");
  }

  const [internalSelectedItems, setInternalSelectedItems] = useState<
    Record<number, SelectedItem>
  >(externalSelectedItems || {});

  useEffect(() => {
    setInternalSelectedItems(externalSelectedItems || {});
  }, [externalSelectedItems]);

  const fetchUserInfo = async (uid: string) => {
    const userRef = doc(db, "runners", uid);
    const userSnap = await getDoc(userRef);
    return userSnap.data();
  };

  let items: Items[] = [];
  if (post.NumOfRunner && post.Type === "sectionForcast") {
    items = generateSectionItems(post.NumOfRunner);
  } else if (post.Type === "MVP") {
    items = [generateMVPItem()];
  }

  const goToSelectPage = (itemId: number) => {
    // 選択されているかどうかに関わらず遷移する
    const scrollPosition = window.scrollY;
    localStorage.setItem("scrollPosition", String(scrollPosition));
    navigate(`/select-runner/${itemId}`, {
      state: { event: event, post: post },
    });
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    // localStorageからスクロール位置を取得
    const savedScrollPosition = localStorage.getItem("scrollPosition");

    if (savedScrollPosition) {
      // スクロール位置を復元
      window.scrollTo(0, Number(savedScrollPosition));
    }
  }, []);

  useEffect(() => {
    const fetchSelectedItems = async () => {
      // selectedItems が空の場合だけ Firestore からデータを取得
      if (user && Object.keys(internalSelectedItems).length === 0) {
        try {
          const userDoc = doc(
            db,
            "users",
            user.uid,
            "events",
            event.Title,
            "types",
            post.Type
          );
          const docSnapshot = await getDoc(userDoc);
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data && data.runnerlist) {
              // ここで selectedItems を Firebase から取得したデータで補完します
              setInternalSelectedItems(data.runnerlist);
            }
          }
        } catch (error) {
          console.error("エラーが発生しました: ", error);
        }
      }
    };

    fetchSelectedItems();
  }, [user, event, post, internalSelectedItems]);

  let [selectedItemswithinfo, setSelectedItemswithinfo] = useState<
    Record<number, UserInfo>
  >({});
  type UserInfoMap = Record<number, UserInfo>;

  useEffect(() => {
    const transformItems = async () => {
      try {
        const resultMap: UserInfoMap = {};
        for (let key of Object.keys(internalSelectedItems)) {
          const numericKey = parseInt(key);
          const entry = internalSelectedItems[numericKey];
          const userInfo = await fetchUserInfo(entry.uid);

          const userInfoObject: UserInfo = {
            id: entry.id,
            name: userInfo?.name || "",
            grade: userInfo?.grade || "",
            affiliation: userInfo?.affiliation || "",
            likeaboutmyhometown: userInfo?.likeaboutmyhometown || "",
            best5000m: userInfo?.best5000m || "",
            best10000m: userInfo?.best10000m || "",
            enthusiasmfortheIzumo: userInfo?.enthusiasmfortheIzumo || "",
            imgUrl: userInfo?.imgUrl || "",
            thumbnail: userInfo?.thumbnail || "",
          };

          resultMap[numericKey] = userInfoObject;
        }

        setSelectedItemswithinfo(resultMap);
      } catch (error) {
        console.error("エラーが発生しました: ", error);
      } finally {
      }
    };

    transformItems();
  }, [internalSelectedItems]);

  interface XShareButtonProps {
    url: string; // シェアしたいURL
    text?: string; // シェアしたいテキスト
  }

  const generateShareText = (
    items: Record<number, SelectedItem>,
    Type: String
  ) => {
    let text = "";

    for (const key in selectedItemswithinfo) {
      if (selectedItemswithinfo.hasOwnProperty(key)) {
        if (Type === "sectionForcast") {
          text += `${key}区: ${selectedItemswithinfo[key].name}\n`;
        } else if (Type === "MVP") {
          text = `MVP: ${selectedItemswithinfo[key].name}\n`;
        }
      }
    }

    return text.trim();
  };

  const XShareButton: React.FC<XShareButtonProps> = ({ url, text = "" }) => {
    const handleClick = () => {
      const xURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        text
      )}&url=${encodeURIComponent(url)}`;

      window.open(xURL, "_blank", "width=600,height=300");
    };

    return (
      <div className="text-center">
        <button
          className="btn btn-hover w-240 btn-dark my-2"
          onClick={handleClick}
        >
          X でシェア
        </button>
      </div>
    );
  };

  const saveToFirestore = async () => {
    // ユーザーに確認を取る
    const isConfirmed = window.confirm("この内容で投稿しますか？");
    // 未選択の項目が存在するか確認
    const isAnyEmpty = items.some((item) => !internalSelectedItems[item.id]);

    if (isAnyEmpty) {
      alert("全ての項目を選択してください");
      return;
    } else if (isConfirmed && user) {
      try {
        setLoading(true);
        const userDoc = doc(
          db,
          "users",
          user.uid,
          "events",
          event.Title,
          "types",
          post.Type
        );
        await setDoc(
          userDoc,
          { runnerlist: internalSelectedItems },
          { merge: true }
        );
        navigate("/");
        alert("投稿しました！");
      } catch (error) {
        console.error("エラーが発生しました: ", error);
      } finally {
        setLoading(false);
      }
    } else {
      navigate("/post", { state: { event: event, post: post } });
    }
  };

  return (
    <div>
      <EventHeader event={event} />
      <div className="container d-flex">
        <div className="postBanner row col-9">
          <EventDetails event={event} post={post} />
        </div>
      </div>
      <div className="container text-center">
        <div className="list-group text-bold">
          <div className="btn list-group-item list-group-item-action3 my-4">
            {items.map((item) => (
              <div
                key={item.id}
                onClick={() => goToSelectPage(item.id)}
                className={`row list-group-item-100 section-selector link-underline link-underline-opacity-0 my-4 ${
                  selectedItemswithinfo[item.id]
                    ? "bg-primary"
                    : "bg-non-available"
                }`}
                style={{
                  backgroundColor: selectedItemswithinfo[item.id]
                    ? "#cf0100"
                    : "transparent",
                }}
              >
                <h3 className="col-3">{item.name}</h3>

                <div className="col-1 bg-white dia-line"></div>
                <div className="col-8 m-0 d-flex align-items-center">
                  <div className="col-5 px-4">
                    <img
                      src={
                        selectedItemswithinfo[item.id] &&
                        selectedItemswithinfo[item.id].thumbnail
                          ? `https://drive.google.com/uc?id=${selectedItemswithinfo[item.id].thumbnail}&.JPG`
                          : `${runner}`
                      }
                      className="img-fluid runner-icon"
                      alt="..."
                    />
                  </div>
                  <div className="col-7 p-0">
                    <h3 className="mb-0 text-left">
                      {(selectedItemswithinfo[item.id] &&
                        `${selectedItemswithinfo[item.id].name}`) ||
                        "（ 未選択 ）"}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="text-center">
        <button
          onClick={saveToFirestore}
          className="btn btn-hover w-240 bg-primary my-2"
        >
          投票
        </button>
      </div>
      <XShareButton
        url="https://chuo-long-distance.club"
        text={`【${eventTitleformatter(event.Title)} ${postTypeformatter(
          post.Type
        )}】\n${generateShareText(
          internalSelectedItems,
          post.Type
        )}\n\n【応援コメント】\n（応援コメントをこちらに！）\n\n#ONE_CHUO\n\nCHUO LONG DISTANCE FUN CLUBから投票！`}
      />

      <HomeButton />
    </div>
  );
};

export default PostPage;
