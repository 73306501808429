import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import HomeButton from "../components/Button";

const LoginPage = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();

  const register = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (user) {
        // Update the profile with the nickname
        await updateProfile(user, {
          displayName: nickname,
        });

        // Optional: Save the nickname in Firestore
        const userDoc = doc(db, "users", user.uid);
        await setDoc(userDoc, {
          nickname,
        });
        setMessage("登録完了");
      }
    } catch (error: any) {
      console.log(error);
      if (error instanceof Error) {
        setMessage(`登録できませんでした。6文字以上にする必要があります。`);
      }
    }
  };

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // ログイン成功時の処理
      navigate("/");
    } catch (error: any) {
      if (error instanceof Error) {
        setMessage(`ログインできませんでした`);
      }
    }
  };

  return (
    <div>
      <div className="container my-4">
        <div className="text-center">
          <h3 className="my-4">ログイン</h3>
          <p>
            <input
              type="email"
              placeholder="メールアドレス"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </p>
          <p>
            <input
              type="password"
              placeholder="パスワード"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </p>
          <button
            onClick={handleLogin}
            className="btn btn-hover w-180 bg-primary my-2"
          >
            ログイン
          </button>

          <p>{message}</p>
        </div>
      </div>
      <div className="container text-center">
        <a href="mailto:command.kraft+funsite&#64;gmail.com?subject=お問い合わせ&body=【お問い合わせ内容（どちらかお選びください）】%0D%0Aパスワードの再発行・アカウントの削除%0D%0A%0D%0A※ お手続きにお時間をいただく場合がございます。%0D%0A※ 必ず登録したメールアドレスで送信してください。">
          パスワードの再発行、アカウントの削除は
          <br />
          こちらからお問い合わせください
        </a>
      </div>
      <div className="container my-4">
        <div className="text-center">
          <h3 className="my-4">新規登録</h3>
          <p>
            <input
              type="text"
              placeholder="ニックネーム"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
          </p>
          <p>
            <input
              type="email"
              placeholder="メールアドレス"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </p>
          <p>
            <input
              type="password"
              placeholder="パスワード"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </p>
          <button
            onClick={register}
            className="btn btn-hover w-180 bg-primary my-2"
          >
            登録
          </button>
          <p>{message}</p>
        </div>
      </div>
      <HomeButton />
    </div>
  );
};

export default LoginPage;
