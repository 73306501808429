import React, { useRef } from "react";
import Slider from "react-slick";

import "../App.css";

interface ProfileData {
  imageName: string;
}

const localProfiles: ProfileData[] = [
  { imageName: "pbc_9.jpg" },
  { imageName: "pbc_43.jpg" },
  { imageName: "pbc_51.jpg" },
  { imageName: "pbc_56.jpg" },
  { imageName: "pbc_67.jpg" },
  { imageName: "pbc_68.jpg" },
  { imageName: "pbc_69.jpg" },
  { imageName: "pbc_70.jpg" },
  { imageName: "pbc_84.jpg" },
  { imageName: "pbc_85.jpg" },
  { imageName: "pbc_86.jpg" },
  { imageName: "pbc_87.jpg" },
  { imageName: "pbc_88.jpg" },
  { imageName: "pbc_89.jpg" },
  { imageName: "pbc_90.jpg" },
  { imageName: "pbc_91.jpg" },
  { imageName: "pbc_92.jpg" },
  { imageName: "pbc_93.jpg" },
  { imageName: "pbc_94.jpg" },
  { imageName: "pbc_95.jpg" },
  { imageName: "pbc_96.jpg" },
  { imageName: "pbc_97.jpg" },
  { imageName: "pbc_100.jpg" },
  { imageName: "pbc_extra1.jpg" },
  { imageName: "pbc_extra2.jpg" },
  // ... 他の画像ファイル名
];

const ProfileCarousel: React.FC = () => {
  const sliderRef = useRef<Slider>(null); // カルーセルの参照を取得するための ref

  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: (currentIndex: number) => {},
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleSlideClick = (index: number) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index); // クリックされたスライドに移動
    }
  };

  return (
    <div className="container py-5">
      <h3 className="h3-moving-bg">PB Collection</h3>
      <Slider ref={sliderRef} {...settings}>
        {localProfiles.map((profile, index) => (
          <div key={index} onClick={() => handleSlideClick(index)}>
            <img
              className="profile-image-card"
              src={require(`../assets/image/PB_Collection/${profile.imageName}`)}
              alt="Profile"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProfileCarousel;
