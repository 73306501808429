import { useNavigate } from "react-router-dom";

const HomeButton: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateHome: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    navigate("/");
  };
  return (
    <div className="text-center">
      <button
        onClick={handleNavigateHome}
        className="btn btn-hover w-180 my-2"
      >
        ホームに戻る
      </button>
    </div>
  );
};

export default HomeButton;

