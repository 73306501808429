import { eventTitle, postType, State } from "./types";

const eventTitletranslationMap: Record<eventTitle, string> = {
  Izumo: "出雲駅伝",
  Zennihon: "全日本大学駅伝",
  Hakone: "箱根駅伝",
};

export function eventTitleformatter(type: eventTitle): string {
  return eventTitletranslationMap[type] || "読み込めませんでした";
}

const postTypetranslationMap: Record<postType, string> = {
  sectionForcast: "区間予想",
  MVP: "MVP投票",
  unknown:""
};

export function postTypeformatter(type: postType): string {
  return postTypetranslationMap[type] || "読み込めませんでした";
}

const StatetranslationMap: Record<State, string> = {
  previous: "準備中",
  next: "直近",
  inProgress: "投票受付",
  result: "結果発表",
  unknown:""
};

export function Stateformatter(type: State): string {
  return StatetranslationMap[type] || "読み込めませんでした";
}
