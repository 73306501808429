import React, { useEffect, useState } from "react";
import { User, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase";

import { FiLogOut } from "react-icons/fi";
import runner from "../assets/image/Runner.png";

import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  // Firebaseのログイン状態に変更があった場合に呼ばれる
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    // コンポーネントがアンマウントされたときにリスナーを解除
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      alert("ログアウトしました！");
      navigate("/login");
    } catch (error) {
      console.error("Failed to sign out:", error);
    }
  };

  return (
    <header>
      <div className="container">
        <div className="d-flex flex-row bd-highlight mb-4  bg-primary">
          <div className="row align-items-start text-white text-bold">
            <div className="pl-5 py-5">
              <h1 className="title-font">
                <a
                  href="/"
                  className="link-offset-2 link-underline link-underline-opacity-0 text-white"
                >
                  CHUO LONG DISTANCE FUN CLUB
                </a>
              </h1>
            </div>
            {user ? (
              // ログイン中
              <div className="d-flex flex-row bd-highlight mb-4 px-5 align-items-center">
                ようこそ {user.displayName ? user.displayName : "ゲスト"} さん
                <button className="mx-4 btn bg-primary" onClick={handleLogout}>
                  <FiLogOut />
                </button>
              </div>
            ) : (
              // 未ログイン
              <div className="d-flex flex-row bd-highlight mb-4 px-4">
                <Link to="/login" className="px-1 py-2 bd-highlight">
                  <button className="mx-2 btn bg-primary">ログイン</button>
                </Link>

                <Link to="/login" className="px-1 py-2 bd-highlight">
                  <button className="mx-2 btn bg-primary">登録</button>
                </Link>
              </div>
            )}
          </div>
          <div className="parent-container">
            <img src={runner} className="img-fluid full-size-image" alt="..." />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
