import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import EventDetails from "./EventDetails";

import { eventProps, postProps } from "./types";

interface EventPostBannerProps {
  event: eventProps;
  post: postProps;
}

function useIsAuthenticated(): boolean {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe();
  }, []);

  return isAuthenticated;
}

const EventPostBanner: React.FC<EventPostBannerProps> = ({ event, post }) => {
  const isAuthenticated = useIsAuthenticated();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (!isAuthenticated) {
      alert("ログインが必要です。");
    }
  };

  const postPath = isAuthenticated ? "/post" : "/login";
  const resultPath = isAuthenticated ? "/result" : "/login";


  if (post.State === "previous") {
    return (
      <div className="container d-flex">
        <div className="previous postBanner row col-9">
          <EventDetails event={event} post={post} />
        </div>
      </div>
    );
  } else if (post.State === "inProgress" && post.Type === "sectionForcast") {
    return (
      <div className="container d-flex align-items-center">
        <div className="primary postBanner row col-9">
          <EventDetails event={event} post={post} />
        </div>
        <div className="col-3">
          <Link
            to={postPath}
            onClick={handleClick}
            state={isAuthenticated ? { event: event, post: post } : {}}
            className="btn btn-postBanner bg-primary"
            role="button"
          >
            投票
          </Link>
        </div>
      </div>
    );
  } else if (post.State === "result" && post.Type === "sectionForcast") {
    return (
      <div className="container d-flex align-items-center">
        <div className="secondary postBanner row col-9">
          <EventDetails event={event} post={post} />
        </div>
        <div className="col-3">
          <Link
            to={resultPath}
            onClick={handleClick}
            state={isAuthenticated ? { event: event, post: post } : {}}
            className="btn btn-postBanner bg-secondary"
            role="button"
          >
            結果
          </Link>
        </div>
      </div>
    );
  } else if (post.State === "inProgress" && post.Type === "MVP") {
    return (
      <div className="container d-flex align-items-center">
        <div className="primary postBanner row col-9">
          <EventDetails event={event} post={post} />
        </div>
        <div className="col-3">
          <Link
            to={postPath}
            onClick={handleClick}
            state={isAuthenticated ? { event: event, post: post } : {}}
            className="btn btn-postBanner bg-primary"
            role="button"
          >
            投票
          </Link>
        </div>
      </div>
    );
  } else if (post.State === "result" && post.Type === "MVP") {
    return (
      <div className="container d-flex align-items-center">
        <div className="secondary postBanner row col-9">
          <EventDetails event={event} post={post} />
        </div>
        <div className="col-3">
          <Link
            to={resultPath}
            onClick={handleClick}
            state={isAuthenticated ? { event: event, post: post } : {}}
            className="btn btn-postBanner bg-secondary"
            role="button"
          >
            結果
          </Link>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default EventPostBanner;
