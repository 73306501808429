import React from "react";
import Slider from "react-slick";

import "../App.css";

interface ImageData {
  imageName: string;
  linkUrl: string;
}

const localImages: ImageData[] = [
  { imageName: "nike.png", linkUrl: "https://www.nike.com/jp" },
  { imageName: "ohya.png", linkUrl: "http://www.human-ohya.co.jp/" },
  { imageName: "Regain.png", linkUrl: "https://regain-suppli.jp/" },
  { imageName: "tachihi.png", linkUrl: "https://www.tachihi.co.jp/" },
];

const SponsorCarousel: React.FC = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,
          }
      }
  ]
  };

  return (
    <div className="container my-3">
      <div className="carousel-container">
        <Slider {...settings}>
          {localImages.map((image, index) => (
            <div key={index}>
              <a href={image.linkUrl} target="_blank" rel="noopener noreferrer">
                <img
                  className="carousel-image"
                  src={require(`../assets/image/sponsor/${image.imageName}`)}
                  alt={`Slide ${index}`}
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SponsorCarousel;
