import { Items } from "../components/types";

  
export const generateSectionItems = (count: number):Items[] => {
    const items: Items[] = [];
    for (let i = 1; i <= count; i++) {
      items.push({
        id: i,
        name: `${i}区`
      });
    }
    return items;
  }
  
export const generateMVPItem = (): Items => {
    return {
      id: 1,
      name: 'MVP'
    };
  }