import { eventProps } from "./types";
import { eventTitleformatter } from "./stringFormatter";

interface EventCounterProps {
  event: eventProps;
}

const EventCounter: React.FC<EventCounterProps> = ({ event }) => {
  // 今日の日付を日本時間で取得
  const today = new Date();
  today.setHours(today.getHours() + 9); // UTCからJSTに変換

  // 目的の日付を設定
  const targetDate = new Date(event.Period);
  targetDate.setHours(9, 0, 0, 0);

  // 日数の差を計算
  const oneDay = 24 * 60 * 60 * 1000; // 1日のミリ秒数
  const diffDays = Math.ceil((targetDate.getTime() - today.getTime()) / oneDay);

  return diffDays >= 0 ? (
    <h3 className="primary text-center fw-bold p-4 border border-primary p-3">
      {eventTitleformatter(event.Title)} まで あと {diffDays} 日
    </h3>
  ) : null;
};

export default EventCounter;
