import React from "react";
import { eventProps } from "./types";
import { eventTitleformatter } from './stringFormatter';


interface EventHeaderProps {
  event: eventProps;
}

const EventHeader: React.FC<EventHeaderProps> = ({ event }) => {
  if (event.State === "inProgress" || event.State === "next") {
    return (
      <div className="container bg-primary p-2 mt-2">
        <h2 className="fs-lg text-white p-4">
          {event.Period}　{eventTitleformatter(event.Title)}
        </h2>
      </div>
    );
  } else if (event.State === "previous") {
    return (
      <div className="container d-flex flex-row bg-previous mg-0">
        <h5 className="fs-lg text-white text-bold p-4 my-2">{event.Period}</h5>
        <h5 className="fs-lg text-white text-bold p-4 my-2">
          {eventTitleformatter(event.Title)}
        </h5>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default EventHeader;
