import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import EventHeader from "../components/EventHeader";
import HomeButton from "../components/Button";

import runner from "../assets/image/Runner.png";

import { eventProps, postProps, SelectedItem } from "../components/types";

import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

interface SelectPlayerPageProps {
  event: eventProps;
  post: postProps;
  selectedItems: Record<number, SelectedItem>;
  setSelectedItems: React.Dispatch<
    React.SetStateAction<Record<number, SelectedItem>>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectPlayerPage: React.FC<SelectPlayerPageProps> = ({
  event: defaultEvent,
  post: defaultPost,
  selectedItems,
  setSelectedItems,
  setLoading,
}) => {
  const { itemId } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const event = location.state?.event || defaultEvent;
  const post = location.state?.post || defaultPost;

  const alreadySelectedIds = Object.values(selectedItems).map(
    (item) => item.id
  );

  // runnerlistを取得
  const [supplementaryItems, setsupplementaryItems] = useState<any[]>([]);

  useEffect(() => {
    const fetchRunnersData = async () => {
      const currentYear = new Date().getFullYear().toString();
      const runnerItems: any[] = [];
      try {
        // 最初のコレクションからデータを取得
        const runnerDocRef = doc(
          db,
          "events",
          currentYear + event.Title,
          "data",
          "runners"
        );
        const runnerDocSnap = await getDoc(runnerDocRef);
        if (runnerDocSnap.exists()) {
          const runnerData = runnerDocSnap.data();
          // uidを使用して次のコレクションからデータを取得
          for (let i = 1; i <= 10; i++) {
            const fieldName = i.toString();
            const runnerUid = runnerData[fieldName];
            if (runnerUid) {
              const runnerInfoRef = doc(db, "runners", runnerUid);
              const runnerInfoSnap = await getDoc(runnerInfoRef);
              if (runnerInfoSnap.exists()) {
                const dataWithId = {
                  ...runnerInfoSnap.data(),
                  id: "10" + i,
                  uid: runnerUid,
                };
                runnerItems.push(dataWithId);
              }
            }
          }
        }
      } catch (error) {
        alert('サーバからの応答がありません。ホームに戻ります。');
        navigate('/');
      }

      setsupplementaryItems(runnerItems.sort((a, b) => a.grade - b.grade));
    };

    fetchRunnersData();
  }, [event, navigate, setLoading]);

  if (!itemId) {
    // itemIdがundefinedまたは空の場合、エラー処理や遷移等を行う
    console.error("Item ID is missing.");
    navigate("/postRunner");
    return null;
  }

  const handleSupplementaryItemClick = (supplementaryItem: SelectedItem) => {
    // このアイテムが既に選択されているか確認
    const alreadySelected =
      selectedItems[parseInt(itemId, 10)]?.id === supplementaryItem.id;

    // 既に選択されている場合はListPageに戻る
    if (alreadySelected) {
      navigate("/post", { state: { event: event, post: post } });
      return;
    }

    // このアイテムが既に選択されているか確認
    else if (alreadySelectedIds.includes(supplementaryItem.id)) {
      alert(
        "この選手はすでに選択されています。\n同じ選手が2区間以上走ることはありません。\n選び直してみましょう"
      );
      return;
    }

    // 選択されていない場合は、このアイテムで既存のアイテムを置き換える（入れ替える）
    setSelectedItems({
      ...selectedItems,
      [parseInt(itemId, 10)]: {
        id: supplementaryItem.id,
        uid: supplementaryItem.uid,
      },
    });
    navigate("/post", { state: { event: event, post: post } });
  };
  return (
    <div>
      <EventHeader event={event} />
      <div className="container">
        <div className="list-group">
          <div className="btn list-group-item list-group-item-action3 my-4">
            {supplementaryItems.map((item) => (
              <div
                className="row custom-border section-selector bg-primary link-underline link-underline-opacity-0 my-4"
                key={item.id}
                onClick={() => handleSupplementaryItemClick(item)}
              >
                <div className="container row py-4">
                  <div className="col-12 col-md-3 m-2">
                    <img
                      src={item.imgUrl ? "https://drive.google.com/uc?id="+item.imgUrl+"&.JPG" : `${runner}`}
                      className="img-fluid runner-img"
                      alt="..."
                    />
                  </div>
                  <div className="col-12 col-md-8 mx-2">
                    <p className="runner-name p-2">{item.name}</p>
                    <div className="runner-detail p-2">
                      所属{" "}
                      <p className="">
                        {item.affiliation} {item.grade}年
                      </p>
                      ベスト記録{" "}
                      <p className="">
                        5000m:{item.best5000m}
                        <br></br>10000m:{item.best10000m}
                      </p>
                      出身地の好きなところ{" "}
                      <p className="">{item.likeaboutmyhometown}</p>
                      意気込み <p className="">{item.enthusiasmfortheIzumo}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <HomeButton />
        </div>
      </div>
    </div>
  );
};

export default SelectPlayerPage;
