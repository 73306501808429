import chuologo from "../assets/image/chuo.png";
import chuorunnerbanner from "../assets/image/banner-RUN.jpg";
import x from "../assets/image/sns/x.png";
import youtube from "../assets/image/sns/youtube.png";
import instagram from "../assets/image/sns/Instagram.png";

import SponsorCarousel from "./SponsorCarousel";

const Footer = () => {
  return (
    <footer>
      <SponsorCarousel />

      <div className="container p-0">
        <div className="d-flex align-items-center justify-content-center">
          <a
            href="https://www.instagram.com/chuo_ekiden/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} className="img-fluid logo mx-4" alt="..." />
          </a>
          <a
            href="https://www.youtube.com/@Chuo_long_distance"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtube} className="img-fluid logo mx-4" alt="..." />
          </a>
          <a
            href="https://twitter.com/chuo_tf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={x} className="img-fluid logo mx-4" alt="..." />
          </a>
        </div>

        <img
          src={chuologo}
          className="rounded mx-auto d-block p-4 my-4"
          alt="..."
        />

        <img src={chuorunnerbanner} className="img-fluid" alt="..." />
      </div>
    </footer>
  );
};

export default Footer;
