import React from "react";
import { eventProps, postProps} from "./types";
import { postTypeformatter, Stateformatter } from './stringFormatter';

interface EventDetailsProps {
  event: eventProps;
  post: postProps;
}

const EventDetails: React.FC<EventDetailsProps> = ({ post }) => {
  return (
    <div>
      <h5 className="fw-bold">{Stateformatter(post.State)}</h5>
      <h1 className="fw-bold">{postTypeformatter(post.Type)}</h1>
      <h5>
        {post.StartDate} ~ {post.EndDate}
      </h5>
    </div>
  );
};

export default EventDetails;
