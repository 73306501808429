import React from "react";

import EventCounter from "../components/EventCounter";
import EventPostBanner from "../components/EventPostBanner";
import EventBanner from "../components/EventHeader";
import ProfileCarousel from "../components/ProfileCarousel";

import SponsorCarousel from "../components/SponsorCarousel";

import { eventProps, postProps } from "../components/types";

interface HomeProps {
  eventIzumo: eventProps;
  eventZennihon: eventProps;
  eventHakone: eventProps;
  postIzumoSectionForcast: postProps;
  postIzumoMVP: postProps;
}

const HomePage: React.FC<HomeProps> = ({
  eventIzumo,
  eventZennihon,
  eventHakone,
  postIzumoSectionForcast,
  postIzumoMVP,
}) => {
  return (
    <div>
      <EventCounter event={eventIzumo} />
      <SponsorCarousel />
      <EventBanner event={eventIzumo} />
      <EventPostBanner event={eventIzumo} post={postIzumoSectionForcast} />
      <EventPostBanner event={eventIzumo} post={postIzumoMVP} />
      <EventBanner event={eventZennihon} />
      <EventBanner event={eventHakone} />
      <ProfileCarousel />
    </div>
  );
};

export default HomePage;
