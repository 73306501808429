import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import "./App.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import LoadingScreen from "./components/LoadingScreen";

import HomePage from "./Page/HomePage";
import PostPage from "./Page/PostPage";
import ResultPage from "./Page/ResultPage";


import SelectPlayerPage from "./Page/SelectPlayerPage";
import LoginPage from "./Page/LoginPage";

import {eventProps, postProps, SelectedItem} from './components/types'



function App() {
  const [selectedItems, setSelectedItems] = useState<
    Record<number, SelectedItem>
  >({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // データの取得や何らかの処理を模倣するための非同期関数
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const Izumo:eventProps = {
    Title: "Izumo",
    Period: "2023/10/09",
    State: "next",
  };
  const Zennihon:eventProps = {
    Title: "Zennihon",
    Period: "2023/11/03",
    State: "previous",
  };
  const Hakone:eventProps = {
    Title: "Hakone",
    Period: "2023/01/02 ~ 03",
    State: "previous",
  };
  const postIzumoSectionForcast:postProps = {
    Type: "sectionForcast",
    StartDate: "2023/10/05",
    EndDate: "2023/10/09",
    State: "inProgress",
    NumOfRunner: 6,
  };
  const postIzumeMVP:postProps = {
    Type: "MVP",
    StartDate: "2023/10/09",
    EndDate: "2023/10/09",
    State: "previous",
  };
  const postEmpty:postProps = {
    Type: "unknown",
    StartDate: " ",
    EndDate: " ",
    State: "unknown",
  };

  return (
    <body>
      <Router>
        <Header />
        <main className="container">
          <Routes>
            <Route
              path="/"
              element={
                <HomePage
                  eventIzumo={Izumo}
                  eventZennihon={Zennihon}
                  eventHakone={Hakone}
                  postIzumoSectionForcast={postIzumoSectionForcast}
                  postIzumoMVP={postIzumeMVP}
                />
              }
            />
            <Route
              path="/select-runner/:itemId"
              element={
                <SelectPlayerPage
                  event={Izumo}
                  post={postEmpty}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  setLoading={setLoading}
                />
              }
            />
            <Route
              path="/post"
              element={
                <PostPage
                  selectedItems={selectedItems}
                  event={Izumo}
                  post={postEmpty}
                  setLoading={setLoading}
                />
              }
            />
            <Route
              path="/result"
              element={
                <ResultPage
                  selectedItems={selectedItems}
                  event={Izumo}
                  post={postEmpty}
                  setLoading={setLoading}
                />
              }
            />
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </body>
  );
}

export default App;
